.Home {
    width: 100%;
    /* height: 100vh; */
    background-color: #000;
    background-image: radial-gradient(circle at top right, rgba(121, 68, 154, 0.13),       transparent),
    radial-gradient(circle at 20% 80%, rgba(41, 196, 255, 0.13), transparent)
}
canvas {
    position: fixed;
    width: 100%;
    height: 100vh;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    /* z-index: -1; */
}
