.Homepage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    /* background: #f3f3f3; */
    /* background-image: radial-gradient(circle at top right, rgba(121, 68, 154, 0.13), transparent), radial-gradient(circle at 20% 80%, rgba(41, 196, 255, 0.13), transparent); */
    /* background-color: #000; */
    /* background-image: radial-gradient(circle at top right, rgba(121, 68, 154, 0.13),       transparent), */
    /* radial-gradient(circle at 20% 80%, rgba(41, 196, 255, 0.13), transparent); */
    font-family: Helvetica, Arial, sans-serif;
}

.center {
    text-align: center;
    background: #ffffff;
    padding: 20px;
    border-radius: 20px;
    /* width: 80vh; */
    position: relative;
    z-index: 5;
}

.center img {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 20px;
}

.icons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

#icon {
    color: black;
}
